@import '../../styles/variables.scss';

.searchInput {
  border-right: none;
  height: 40px;
  border-radius: 50px 0 0 50px;
  border: 1px solid white;
  padding-left: 20px;
  outline: none;
  min-width: 225px;
  max-width: 325px;
  width: 65%;
  caret-color: $sizzlyPrimaryDark;
  color: $sizzlyPrimaryDark;
  background: $sizzlyLight;
  &::placeholder {
    background: linear-gradient(0.25turn, $sizzlyPrimaryDark, 9%, $systemRed);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 1 !important;
  }
}

.searchBoxContainer {
  position: relative;
  .searchButton {
    background: white;
    border: 1px solid #dfdfdf;
    width: 40px;
    max-height: 40px;
    height: inherit;
    border-left: none;
    align-items: center;
    border-radius: 50px 50px 50px 50px;
    margin-left: -20px;
    color: $systemRed;
    line-height: 40px;
  }
}

@import '../styles/variables.scss';

.contentContainer {
  overflow: visible;
  background-image: url('/homepage_background.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  @media (max-width: 1500px) {
    min-height: 100vh;
  }
  @media (min-width: 1500px) {
    width: 100%;
    padding-top: 130%; /* 1:1 Aspect Ratio */
    position: relative;
  }
}

.contentContainerMainElement {
  @media (max-width: 1500px) {
    min-height: 25vh;
  }
}

.orderTypeSwitch {
  height: 40px;
  border: 2px solid $sizzlyPrimaryWineRedLight;
  color: $sizzlyPrimaryWineRed;
  white-space: nowrap;
  border-right: 0;
  background-color: $sizzlyPrimaryWineRedLight;
  &:first-child {
    border-radius: 25px 0 0 25px;
    padding: 5px 0 5px 0;
    padding-right: 20px !important;
  }
  &:last-child {
    border-right: 2px solid $sizzlyPrimaryWineRed;
    border-radius: 0 25px 25px 0;
    margin-left: -15px;
    padding-left: 20px !important;
    border-right: 0;
  }
  &.orderTypeSwitchSelected {
    background-color: $sizzlyPrimaryWineRed;
    color: white;
    border-radius: 25px;
    z-index: 10;
    border: 2px solid $sizzlyPrimaryWineRed;
    padding-left: 20px !important;

    &:hover {
      background: $sizzlyPrimaryWineRedDark;
      border: 2px solid $sizzlyPrimaryWineRedDark;
      color: white;
    }
  }
  &:hover {
    background: $sizzlyPrimaryWineRedMedium;
    color: $sizzlyPrimaryWineRed;
    border: 2px solid $sizzlyPrimaryWineRedMedium;
    border-right: 0;
  }
}

.orderTypeSwitchesContainer {
  margin-bottom: 60px;
}

.functionalityHeading {
  color: $sizzlyPrimaryWineRed;
  overflow: visible;
}
.applicationHeading {
  color: white;
}

@media (min-width: 1500px) {
  .contentWrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

@media (min-width: 1850px) {
  .contentContainer {
    background-size: cover;
  }
}
@media (max-width: 1600px) {
  .contentContainer {
    background-size: cover;
  }
}
@media (max-width: 500px) {
  .contentContainer {
    .contentContainerMainElement {
      .orderTypeSwitchesContainer {
        .orderTypeSwitch {
          font-size: 14px;
          height: 35px;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .contentContainer {
    .contentContainerMainElement {
      .orderTypeSwitchesContainer {
        .orderTypeSwitch {
          font-size: 13px;
          height: 35px;
        }
      }
    }
  }
}

@media (max-width: 280px) {
  .contentContainer {
    .contentContainerMainElement {
      .orderTypeSwitchesContainer {
        .orderTypeSwitch {
          font-size: 9px;
          height: 28px;
        }
      }
    }
  }
}

.paymentMethodsElement {
  background-image: url('/payment_methods.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: transparent;
  @media (min-width: 992px) {
    width: 80vw;
    height: 40vh;
  }
  @media (max-width: 992px) {
    width: 75vw;
    height: 25vh;
  }
}

.sizzlyWineRed {
  color: $sizzlyPrimaryWineRed;
}

.sizzlyWineRedBtn {
  background-color: $sizzlyPrimaryWineRed;
  color: white;
  &:hover,
  &:active {
    background: $sizzlyPrimaryWineRedDark;
  }
}

.paragraphWidth {
  @media (min-width: 992px) {
    width: 75%;
  }
}

@import '../../styles/variables.scss';
.orderTypeCarouselContainer {
  padding: 0 40px;
  max-width: 1200px;
  .orderTypeCarouselItem {
    max-width: 350px;
    height: 490px;
    display: flex;
    padding: 0 30px;
    .orderTypeCarouselItemCard {
      background-color: rgba(255, 255, 255, 0.7);
      box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
      width: 100%;
      border: none;
      .orderTypeCarouselItemImageContainer {
        height: 54%;
        display: flex;
        justify-content: center;
        align-items: center;
        .orderTypeCarouselItemImage {
          width: 85%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          transition: background-image 0.5s ease-in-out;
        }
      }
      .orderTypeCarouselItemTextContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-bottom: 20px;
        .orderTypeCarouselItemText {
          flex: 1 1 auto;
        }
        .orderTypeCarouselItemTextDots {
          display: flex;
          width: 50%;
          justify-content: space-between;
          .orderTypeCarouselDot {
            border-radius: 25px;
            width: 10px;
            height: 10px;
            background-color: rgba(255, 255, 255, 0.6);
            &.orderTypeCarouselDotActive {
              background-color: $sizzlyPrimaryWineRed;
            }
          }
        }
      }
    }
  }
  .customRightArrow {
    position: absolute !important;
    right: 3px;
    z-index: 1;
    border: 1px solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    opacity: 0.5;
    cursor: pointer;
    transform: rotate(-45deg);
    &:hover {
      opacity: 1;
    }
  }
  .customLeftArrow {
    position: absolute !important;
    left: 3px;
    z-index: 1;
    border: 1px solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    opacity: 0.5;
    cursor: pointer;
    transform: rotate(135deg);
    &:hover {
      opacity: 1;
    }
  }
}

@import '../..//styles/variables.scss';
.masthead {
  height: 100vh;
  min-height: 500px;
  overflow: hidden;
  .videoOverlay {
    top: 0;
    left: 0;
    height: auto;
    width: auto;
    min-width: 100%;
    min-height: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
  }
  video {
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  .mastheadTopLayerContainer {
    z-index: 10;
  }
  .searchBoxContainer {
    position: relative;
    .searchButton {
      background: white;
      border: none;
      width: 40px;
      max-height: 40px;
      height: inherit;
      border-left: none;
      align-items: center;
      border-radius: 50px 50px 50px 50px;
      margin-left: -20px;
      color: $systemRed;
    }
    .searchInput {
      border-right: none;
      height: 40px;
      border-radius: 50px 0 0 50px;
      border: none;
      padding-left: 20px;
      outline: none;
      min-width: 225px;
      max-width: 325px;
      width: 65%;
      &::placeholder {
        background: linear-gradient(0.25turn, $sizzlyPrimaryDark, 9%, $systemRed);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        opacity: 1 !important;
      }
    }
  }
  .allRestaurantButton {
    width: 224px;
    height: 40px;
    background: $sizzlyPrimaryWineRed;
    border: none;
    border-radius: $radiusBig;
    color: white;
    &:hover,
    &:active {
      background: $sizzlyPrimaryWineRedDark;
    }
  }
}

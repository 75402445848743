@import '../../styles/variables.scss';

.partnerCarouselContainer {
  padding: 0 40px;
  .partnerCarouselItem {
    display: flex;
    margin-top: 5px;
    flex-direction: column;
    align-items: center;
    .partnerCarouselItemImage {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 80%;
      height: 20vh;
      min-height: 20vh;
      border-radius: 8px;
    }
  }
  .customRightArrow {
    position: absolute !important;
    right: 3px;
    z-index: 1;
    border: 1px solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    opacity: 0.5;
    cursor: pointer;
    transform: rotate(-45deg);
    &:hover {
      opacity: 1;
    }
  }
  .customLeftArrow {
    position: absolute !important;
    left: 3px;
    z-index: 1;
    border: 1px solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    opacity: 0.5;
    cursor: pointer;
    transform: rotate(135deg);
    &:hover {
      opacity: 1;
    }
  }
}

.sizzlyWineRed {
  color: $sizzlyPrimaryWineRed;
}

@import '../../styles/variables.scss';
.applicationCarouselContainer {
  padding: 0 40px;
  height: 60vh;
  max-height: 800px;
  .applicationCarouselItem {
    max-width: 800px;
    height: 70vh;
    display: flex;
    .applicationCarouselItemImage {
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top center;
      width: 100%;
      height: 100%;
    }
  }
  .customRightArrow {
    position: absolute !important;
    right: 3px;
    z-index: 1;
    border: 1px solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    opacity: 0.5;
    cursor: pointer;
    transform: rotate(-45deg);
    &:hover {
      opacity: 1;
    }
  }
  .customLeftArrow {
    position: absolute !important;
    left: 3px;
    z-index: 1;
    border: 1px solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    opacity: 0.5;
    cursor: pointer;
    transform: rotate(135deg);
    &:hover {
      opacity: 1;
    }
  }
}
